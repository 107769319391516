import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';

// Initialize UIkit
UIkit.use(Icons);
// UIkit does not support SSR, so we can't import UIkit in each component directly.
// References:
//  https://github.com/uikit/uikit/issues/3207
//  https://getuikit.com/docs/webpack
// Workaround: Expose UIkit on the window object, just like the UIkit script tag.
window.UIkit = UIkit;
